import React, { useEffect, useRef, useState } from "react"
import Button from "../components/UI/Button";
import { Box, TextInput } from "grommet";
import { colors } from "../theme";
import styled from 'styled-components'
import { navigate } from "gatsby";
import { useAuthenticationState } from "../providers/AuthenticationProvider";

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: ${colors.lightGray};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledBox = styled(Box)`
  width: 350px;
  > :first-child {
    color: ${colors.indigo};
    text-transform: uppercase;
    letter-spacing: 1.1;
    font-weight: bold;
    font-size: 21px;
    margin-bottom: 30px;
  }
  
  > * {
    margin-bottom: 15px;
  }

  > :last-child {
    margin-bottom: 0;
  }
`

const ErrorText = styled.div`
  color: #f00;
`

const LoginPage = () => {
  const passwordRef = useRef()
  const emailRef = useRef()
  const { isLoggedIn, getToken } = useAuthenticationState() 
  const [error, setError] = useState()

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/')
    }
  }, [isLoggedIn])


  const submitLogin = async () => {
    try {
      await getToken(emailRef.current.value, passwordRef.current.value)
    } catch (err) {
      setError(err.message)
    }
  }

  return <Wrapper>
      <StyledBox background="#fff" elevation="medium" pad="medium" round="small">
      <div>Login as a tutor</div>
        <ErrorText>{error}</ErrorText>
        <TextInput ref={emailRef} type='email' placeholder='Email address' defaultValue={''} onKeyPress={(e) => e.which === 13 && passwordRef.current.focus()}/>
        <TextInput ref={passwordRef} type='password' defaultValue={''} placeholder='Password' onKeyPress={e => e.which === 13 && submitLogin()}/>
        <Button onClick={submitLogin}>Submit</Button>
      </StyledBox>
    </Wrapper>
}

export default LoginPage
